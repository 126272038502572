@font-face {
    font-family: 'Metropolis-Regular';
    src: local('Metropolis'), url(./fonts/Metropolis/Metropolis-Regular.otf) format('truetype');
}

@font-face {
    font-family: 'Metropolis-Bold';
    src: local('Metropolis'), url(./fonts/Metropolis/Metropolis-Bold.otf) format('truetype');
}

@font-face {
    font-family: 'Metropolis-Light';
    src: local('Metropolis'), url(./fonts/Metropolis/Metropolis-Light.otf) format('truetype');
}

@font-face {
    font-family: 'FjallaOne-Regular';
    src: local('FjallaOne'), url(./fonts/FjallaOne/FjallaOne-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'SourceSansPro-Regular';
    src: local('SourceSansPro'), url(./fonts/SourceSansPro/SourceSansPro-Regular.ttf) format('truetype');
}

body,
html {
    font-family: 'Metropolis-Regular';
}


/* Header */

.bg-nav {
    background-color: #b49f96;
    color: #fff;
}

.bg-nav a {
    color: #ffffff;
    text-decoration: none;
}

header ul.nav>li.nav-item>a.nav-link,
header ul.nav li.dropdown-item>a.nav-link {
    background: none;
    color: #212529;
    line-height: 2rem;
    padding: 0 1rem;
    text-transform: uppercase;
}

ul.nav li a:hover,
ul.nav li .active {
    background: none;
    border-bottom: #b49f96 2px solid;
    border-radius: 0px;
}

.header-products-link {
    cursor: pointer;
}

header img.logo {
    width: 50%;
}

@media screen and (min-width:992px) {
    .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
    }

    .dropdown .dropdown-menu {
        display: none;
    }

    .dropdown:hover .dropdown-toggle::after {
        border-top: 0;
        border-bottom: .3em solid;
    }
}

@media screen and (max-width:992px) {
    .dropdown-toggle.show::after {
        border-top: 0;
        border-bottom: .3em solid;
    }
}

/* Footer */

.bg-footer {
    background-color: #b49f96;
    color: #fff;
}

.bg-footer a {
    color: #fff;
}

.bg-total-bar {
    background-color: #b49f96;
    text-transform: uppercase;
    font-weight: bold;
}

.bg-total-bar-fixed {
    bottom: 0;
    position: fixed;
    width: 100%;
}


.btn.btn-toolbar-cart {
    background-color: #fff;
    border: 2px solid #aaa;
    border-radius: 10px;
    color: #212529;
    font-family: 'Metropolis-Bold';
    font-size: 14px;
    font-weight: bold;
    height: 2rem;
    padding: 0.375rem;
    text-transform: uppercase;
}

.btn.btn-toolbar-cart:hover {
    border: 2px solid #aaa;
    color: #333;
}

.toolbar-total-cart {
    font-family: 'Metropolis-Bold';
    font-size: 1.15rem;
    letter-spacing: 0.1rem;
}

footer img.icon-logo {
    width: 35%;
}

/* Shade */

.shade-container {
    background-color: #f8f8f8;
}

.container-title-header {
    background-color: #ffffff;
    background-image: url("../public/statics/titles-background.jpg");
    background-size: contain;
    height: 4rem;
    text-align: center;
    display: table;
    width: 100%;
}

.shade-title,
.item-title,
.account-signin-title {
    color: #333;
    font-family: 'Metropolis-Regular';
    font-size: 2rem;
    text-transform: uppercase;
    display: table-cell;
    vertical-align: middle;

}

.shade-product-title {
    font-family: 'Metropolis-Regular';
    font-size: 1.8rem;
}

.shade-product-subtitle {
    font-family: 'Metropolis-Regular';
    color: '#606060';
}

.image-shade {
    border-radius: 10px;
    width: 100%;
}

.btn-rounded {
    font-family: 'Metropolis-Bold';
    font-size: 14px;
    color: #333;
    /* letter-spacing: 1px; */
    line-height: 15px;
    border: 1px solid rgb(180, 159, 150);
    border-radius: 40px;
    background: rgb(180, 159, 150);
    background: transparent;
    transition: all 0.3s ease 0s;
    text-transform: uppercase;
}

.btn-rounded:hover {
    color: #fff;
    background: rgb(180, 159, 150);
    border: 1px solid rgb(180, 159, 150);
}

/* Items */

#bg-custom-windows {
    /* background-image: url('../public/statics/bg.jpg'); */
    height: 924px;
}

.accordion.container-steps {
    height: 800px;
    overflow-y: scroll;
}

.accordion .container-step {
    height: 450px;
    overflow-y: scroll;
}

.tail {
    /* height: 100%; */
    /* overflow: hidden; */
    position: relative;
    display: flex;
    /* align-items: center; */
    flex-wrap: wrap;
}

.tail img {
    height: auto;
    position: absolute;
    padding: 0;
    margin: 0 auto;
}

.item-selected {
    border: 1px #b49f96 dotted;
    border-radius: 16px;
    /* box-shadow: 0 0 4px 2px #1e90ff !important; */
}

.item-disabled {
    background-color: dimgray;
    color: linen;
    opacity: 0.2;
}

div.accordion-button,
div.accordion-button:not(.collapsed) {
    background-color: #9599a4;
}

div.accordion-button::after {
    margin-right: 16px;
}

div.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
}

div.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
}

.btn-next-step {
    color: #fff;
    background-color: #b49f96;
    border-color: #b49f96;
}

.btn-next-step:hover {
    color: #fff;
    background-color: #b49f96;
    border-color: #b48070;
}

.btn-back-step {
    color: #fff;
    background-color: #9599a4;
    border-color: #9599a4;
}

.btn-back-step:hover {
    color: #fff;
    background-color: #9599a4;
    border-color: #575b69;
}

.step-number-container {
    background-color: #575b69;
    color: #ffffff;
    text-transform: uppercase;
    width: 5rem;
}

.step-title-container,
.step-selected-container {
    background-color: #9599a4;
    color: #ffffff;
    text-transform: uppercase;
}

.step-selected-container {
    color: white;
    text-align: right;
}

.step-number-container h6,
.step-title-container h6,
.step-selected-container h6 {
    margin-bottom: 0rem;
}

.container-help-text {
    background-color: #efefef;
    color: black;
    font-size: 14px;
    text-align: justify;
}

/* Cart */
.cart-header-table {
    background-color: #9599a4;
    color: #ffffff;
}

.order-container {
    background-color: #f8f8f8;
}

.order-resume-container {
    background-color: #ffffff;
}

.order-container .btn {
    color: #fff;
}

.btn-buy-now,
.btn-abrusci-primary {
    background-color: #9599a4;
    font-family: 'Metropolis-Bold';
    font-size: 17px;
    letter-spacing: 2px;
}

.btn-continue-shopping {
    background-color: #b49f96;
    color: #fff;
    font-family: 'Metropolis-Bold';
    font-size: 17px;
    letter-spacing: 2px;
}

/* Account Sign In */

.sigin-container {
    background-color: #f8f8f8;
}

.sigin-container input {
    background-color: #f8f8f8;
    border: 1px solid #212529;
    border-radius: 0%;
}

.btn-abrusci-primary {
    background-color: #9599a4;
    color: #ffffff;
    font-family: 'Metropolis-Bold';
    font-size: 17px;
    text-transform: uppercase;
}

.account-tittle {
    font-family: 'Metropolis-Bold';
}

form .input-feedback {
    color: rgb(235, 54, 54);
    font-size: 14px;
}

/* Voucher */


.voucher-container {
    background-color: #f8f8f8;
    width: 35%;
}

.voucher-container img {
    width: 8rem;
}

@media only screen and (max-width: 1366px) {
    .feature.col.tail img {
        width: 95%;
        padding-top: 2rem;
    }
}